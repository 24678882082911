
<template>
    <div>
      <ul class="nav mysklad_nav responder_head_margin">
        <li class="nav-item mysklad_nav_item templates" :class="{'active': selected_tab === 'templates'}">
          <a class="nav-link mysklad_nav_link" aria-current="page" href="#templates" @click.prevent="selectTab('templates')">Шаблоны уведомлений</a>
        </li>
        <li class="nav-item mysklad_nav_item settings" :class="{'active': selected_tab === 'settings'}">
          <a class="nav-link mysklad_nav_link" href="#settings" @click.prevent="selectTab('settings')">Настройки</a>
        </li>
      </ul>
      <div class="notification_templates" v-if="selected_tab === 'templates'">
        <div class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
          <span><b>Событие</b></span>
          <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Обязательный  параметр. На какое событие будет срабатывать отправка шаблона уведомления">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
            d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
            fill="#6c757d" />
            </svg>
          </span>
        </div>
        <div>
            <select name="profile" class="form-select" v-model="selected_event" @change="toggleDocumentStatuses">
              <option v-for="(t, i) in events" :value="i"><span v-if="t.active">🟢</span><span v-if="!t.active">⚪</span> {{ t.name}}</option>
            </select>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">Выберите событие для настройки его шаблона</span>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
          <div class="d-flex template_buttons_ms">
            <div v-for="(template, i) in this.events[this.selected_event].dataInstance">
            <div :class="{'active': selected_template == i}" class="selected_template_button" @click="selected_template = i">Шаблон {{ i + 1 }}</div>
            </div>
            <div class="selected_template_add">
              <a v-if="this.events[this.selected_event].dataInstance.length < 20" href="#" @click.prevent="addTemplate()" style="margin-top:3px; display:block;">Добавить шаблон</a>
              <span v-if="this.events[this.selected_event].dataInstance.length > 19" style="color: #D3D3D3;margin-top:3px; display:block;">Добавить шаблон</span>
            </div>
            <div class="selected_template_info d-flex align-items-center">
              <span class="d-flex align-items-center align-self-center" data-toggle="tooltip" data-placement="right" title="На каждое событие может быть создано несколько шаблонов с различными настройками">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                fill="#6c757d" />
                </svg>
              </span>
            </div>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <span><b>Статус шаблона</b></span>
            <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Включить или выключить конкретный шаблон">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
              fill="#6c757d" />
              </svg>
            </span>
        </div>
        <div class="d-flex align-items-center">
            <switch-toggle :responder="null" :uid="'222'" :model.sync="events[selected_event].dataInstance[selected_template].active" :key="selected_uuid + '.' + events[selected_event].type + '.' + events[selected_event].value + '.' + key_iter + '.' + selected_template"
            @toggler="toggleStatus" style="display: inline-block;"></switch-toggle>
            <div v-if="events[selected_event].dataInstance[selected_template].active" class="bot_mini_text always_visible d-inline-block initial active">
                ВКЛЮЧЕН</div>
            <div v-if="!events[selected_event].dataInstance[selected_template].active" class="bot_mini_text always_visible d-inline-block initial">
                ВЫКЛЮЧЕН</div>
        </div>
        </div>

        <div v-if="events.length > 0 && events[selected_event].type == 'UPDATE'" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <b>Параметры события</b>
            <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Обязательный параметр. При каком именно событии (изменении какого поля в документе) будет отправлен шаблон уведомления">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
              fill="#6c757d" />
              </svg>
            </span>
        </div>
        <div>
            <multiselect v-model="events[selected_event].dataInstance[selected_template].onFieldsData" label="name"
                track-by="code" placeholder="Выберите параметр" :options="events[selected_event].onFieldsParams" :multiple="true" :taggable="true" :hide-selected="true">
                <span slot="noResult" style="display: none;"></span>
                <span slot="noOptions">
                Нет доступных параметров
                </span>
            </multiselect>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">Выберите параметры, на изменение которых будет отправлено уведомление</span>
        </div>
        </div>

        <div v-if="events.length > 0 && events[selected_event].type == 'UPDATE' && showDocumentStatuses" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <b>Статусы документа</b>
        </div>
        <div>
            <multiselect v-model="events[selected_event].dataInstance[selected_template].stateListData" label="name"
                track-by="code" placeholder="Выберите статус" :options="events[selected_event].stateListParams" :multiple="true" :taggable="true" :hide-selected="true">
                <span slot="noResult" style="display: none;"></span>
                <span slot="noOptions">
                Нет доступных статусов
                </span>
            </multiselect>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">Выберите параметры, на изменение которых будет отправлено уведомление</span>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
          <div class="form-label d-flex justify-content-between align-items-center">
              <b>Дополнительные параметры события</b>
              <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Необязательный параметр. Это дополнительный фильтр на отправку уведомления. Уведомление будет отправлено только в случае, если текст/значение указанного поля соответствует заданному здесь">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                fill="#6c757d" />
                </svg>
              </span>
          </div>
          <div v-for="(paramData, p) in events[selected_event].dataInstance[selected_template].additionalParamData" class="row mb-2 gx-2 gx-md-4">
            <div class="col-4">
              <select class="form-select" :key="'select_adp_' + p" v-model="events[selected_event].dataInstance[selected_template].additionalParamData[p].code">
                <option v-for="(t, i) in events[selected_event].additionalParams" :value="t.code">{{ t.name}}</option>
              </select>
            </div>
            <div class="col-7">
              <input v-model="events[selected_event].dataInstance[selected_template].additionalParamData[p].value" type="text" name="name" class="form-control" placeholder="Значение поля равно">
            </div>
            <div class="col-1 d-flex align-items-center justify-contant-center"><button @click.prevent="removeAdditionalParam(p)" type="button" aria-label="Удалить параметр" style="display: flex;color:#ccc;border:none;background: none;font-size: 28px;padding: 0;color: #9d9d9d;">×</button></div>
          </div>
          <div class="selected_template_add"><a v-if="events[selected_event].dataInstance.length <= 20" href="#" @click.prevent="addAdditionalParam()" style="margin-top:12px; display:block;color:#6C757D;">Добавить параметр</a></div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <b>Получатель</b>
            <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Обязательный параметр. По умолчанию рекомендуем ставить {agent.phone} - это значит, что телефон получателя будет браться из карточки контрагента. Также вы можете ввести несколько переменных/номеров через запятую">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
              fill="#6c757d" />
              </svg>
            </span>
        </div>
        <div>
            <input :class="{'is-invalid': phone_error != ''}" type="text" name="name" class="form-control" placeholder="Можно ввести несколько получателей через запятую" v-model="events[selected_event].dataInstance[selected_template].recipient" id="reciever_input">
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">
            <div v-if="phone_error != ''"><span style="color: red;">{{ phone_error }}</span></div>
            <div v-for="(rval, rkey) in events[selected_event].recipientParams.fields"><b>{{ rval }}:</b> <a class="crm_var" href="#" @click.prevent="insertTextAtCursor(rkey)">{{ rkey }}</a></div>
            <div><b>номер телефона:</b> 79005551213</div>
            </span>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <span><b>Текст сообщения</b></span>
            <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Обязательный параметр. Значения в переменные подтягиваются автоматически из документа. Список доступных переменных указан снизу, кликабельно">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
              fill="#6c757d" />
              </svg>
            </span>
        </div>

        <crm-textarea :placeholder="'Введите текст шаблона'" :key="events[selected_event].type + '.' + events[selected_event].value + '.' + selected_template"
        :uid="events[selected_event].type + '_' + events[selected_event].value + '_' + selected_template" :text.sync="events[selected_event].dataInstance[selected_template].textData" :text_variables.sync="events[selected_event].textParams"></crm-textarea>

        </div>
      </div>
      <div class="notification_settings mb-2" v-if="selected_tab === 'settings'">
        <div class="form-label responder_head_margin d-flex align-items-center justify-content-center">
          <span><b>Входящие сообщения от клиентов</b></span>
        </div>

        <div class="d-flex flex-column">
          <!--создание нового контрагента-->
          <div class="responder_head_margin">
            <div class="d-flex justify-content-between align-items-center">
              <div class="me-2 d-flex align-items-center">
                <input type="checkbox" class="form-check-input" v-model="is_creatingNewCounterpart" id="createNewCounterpart" name="createNewCounterpart" @change="() => { 
                  createAgentClearHandler();
                  this.moysklad_edited_content = true; 
                }" />
                <label class="form-check-label" for="createNewCounterpart">Создавать нового контрагента по входящему сообщению</label>
              </div>
              <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Если написал новый клиент, которого нет в CRM, то будет создан новый контрагент">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                </svg>
              </span>
            </div>
          </div>
          <!--статус нового контрагента-->
          <div v-if="is_creatingNewCounterpart">
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newCounterpartStatus">Какой статус устанавливать у контрагента</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При создании нового контрагента будет устанавливаться выбранный статус">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <select class="form-select" name="newCounterpartStatus" id="newCounterpartStatus" v-model="defaultCounterpartStatus" @change="() => { this.moysklad_edited_content = true }">
                  <option selected disabled>Выберите статус</option>
                  <option :value="null">Не выбран</option> 
                  <option v-for="item in counterpartStatusList" :value="item">{{ item.value }}</option>
                </select>
              </div>
            </div>
            </div>
            <!--группа нового контрагента-->
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newCounterpartTags">Какие группы добавлять контрагенту</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При создании нового контрагента будут установлены указанные группы. Напишите название группы и нажмите Enter, можно задать несколько значений">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <Multiselect v-model="newCounterpartGroups" :options="counterpartGroupTagsList" :multiple="true" :close-on-select="false" :taggable="true"  :showNoResults="false" :showNoOptions="false" ref="counterpart_tags"
                placeholder="Выберите или введите новый тег" tag-placeholder="Введите новый тег" @tag="addTag" 
                @select="handleMultiselectUpdate"
                @remove="handleMultiselectUpdate"
                @open="handleEnter"/>
              </div>
            </div>
            </div>
            <!--комментарий к контрагенту-->
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newCounterpartTags">Какой комментарий добавлять контрагенту</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При создании нового контрагента будет добавлен комментарий. Можно оставить пустым">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <textarea v-model="counterpartComment" name="counterpart_comment" id="counterpart_comment" class="form-control" ref="textarea1" placeholder="Введите комментарий" 
                @change="()=>{
                  this.moysklad_edited_content = true
                  textAreaResize(1)
                }"
                @input="textAreaResize(1)"
                @cut="textAreaResize(1)"
                @copy="textAreaResize(1)"
                @paste="textAreaResize(1)"></textarea>
              </div>
            </div>
            </div>
          </div>
          <!--новый заказ по входящему сообщению-->
          <div class="responder_head_margin">
            <div class="d-flex align-items-stretch justify-content-between" :class="{'disabled': !is_creatingNewCounterpart}">
              <div class="me-2 d-flex align-items-center">
                <input type="checkbox" class="form-check-input" name="newOrderOnMessage" id="newOrderOnMessage" v-model="is_newOrderOnMessage" @change="() => { createOrderClearHandler(); this.moysklad_edited_content = true;}"/>
                <label class="form-check-label" for="newOrderOnMessage">Создавать новый заказ по входящему сообщению</label>
              </div>
              <div class="d-flex align-items-center">
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При входящем сообщении будет автоматически создан новый заказ покупателя, если нет созданного ранее">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div v-if="is_newOrderOnMessage">
            <!--статус нового заказа по входящему сообщению-->
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newOrderStatus">Какой статус устанавливать у заказа</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При создании нового заказа будет устанавливаться выбранный статус">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <select class="form-select" name="newOrderStatus" v-model="newOrderStatus"  @change="() => this.moysklad_edited_content = true">
                  <option value="" disabled selected>Выберите статус</option>
                  <option :value="null">Не выбран</option> 
                  <option v-for="item in orderStatusList" :value="item">{{ item.value }}</option>
                </select>
              </div>
            </div>
            </div>
            <!--статусы-исключения-->
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newOrderStatus">Статусы заказов - исключения</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="Новый заказ не будет создан, если у этого контрагента уже есть заказ в одном из этих статусов. Опция нужна для того, чтобы во время переписки с клиентом не создавались новые заказы на каждое его сообщение">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <Multiselect v-model="excludeStatusList" :options="orderStatusList" :showNoResults="false" :showNoOptions="false" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Выберите статусы для исключения" label="value"
                 track-by="value" 
                 @open="handleMultiselectUpdate"
                 @select="handleMultiselectUpdate"
                 @remove="handleMultiselectUpdate"
                 ></Multiselect>
                <!--<select class="form-control" name="excludeStatuses" v-model="excludeStatusList"  @change="() => this.moysklad_edited_content = true">
                  <option v-for="item in orderStatusList" :value="item">{{ item.value }}</option>
                </select>-->
              </div>
            </div>
            </div>
            <!--организация-->
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newOrderStatus">На какую организацию оформлять заказ</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При создании нового заказа будет устанавливаться выбранная организация">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <select class="form-select" name="currentOrganization" v-model="currentOrganization"  @change="() => this.moysklad_edited_content = true">
                  <option selected disabled>Выберите организацию</option>
                  <option v-for="item in organizationsList" :value="item">{{ item.value }}</option>
                </select>
              </div>
            </div>
            </div>          
            <!--Комментарий к заказу-->
            <div class="responder_head_margin">
            <div class="d-flex flex-column align-items-stretch justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <label class="form-label" for="newCounterpartTags">Какой комментарий добавлять к заказу</label>
                <span class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" title="При создании нового заказа будет добавлен комментарий. Можно оставить пустым" aria-label="Какой комментарий добавлять к заказу">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                  fill="#6c757d" />
                  </svg>
                </span>
              </div>
              <div>
                <textarea v-model="orderComment" name="order_comment" id="order_comment" class="form-control" ref="textarea2" placeholder="Введите комментарий к заказу" 
                @change="()=>{
                  this.moysklad_edited_content = true
                  textAreaResize(2)
                }"
                @input="textAreaResize(2)"
                @cut="textAreaResize(2)"
                @copy="textAreaResize(2)"
                @paste="textAreaResize(2)"></textarea>
              </div>
            </div>
            </div>
          </div>          
        </div>
      </div>
      <!--Сабмиты-->
      <div class="responder_head_margin responder_submit_buttons">
        <a v-if="!moysklad_sending" @click="submit_button" class="resp_button_green" :class="{'disable': !moysklad_edited_content}">Сохранить</a>
        <a v-if="moysklad_sending" class="resp_button_green disable"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></a>
        <a v-if="events.length > 0 && events[selected_event].dataInstance.length > 1" @click="reset_button(selected_template)" class="resp_button_red ms-2">Удалить шаблон</a>
      </div>
    </div>     
  </template>
  <script>
  import SwitchToggle from './SwitchToggle.vue';
  import CrmTextarea from './CRMTextarea.vue';
  import Multiselect from 'vue-multiselect';
  
  export default {
    data() {
      return {
          selected_tab: 'templates', // выбранная вкладка в меню. Значения: templates, settings
          selected_uuid: this.current_profile.uuid,
          selected_event: 0,
          selected_template: 0,
          main_text: '',
          reciever: '',
          profile: null,
          events: [],
          moysklad_edited_content: false,
          moysklad_sending: false,
          initialLoad: true,
          key_iter: 0,
          phone_error: "",
          showDocumentStatuses: false,
          // новые поля для настроек на входящие сообщения
          // контрагенты
          is_creatingNewCounterpart: false, // новвй контрагент на входящее сообщение, bool
          counterpartStatusList: [], //список доступных статусов контрагента, arr of hrefData
          defaultCounterpartStatus: null, //выбранный статус контрагента, hrefData
          counterpartGroupTagsList: [], //список достуных тегов, arr of string
          newCounterpartGroups: [], // выбранные теги, arr of string
          counterpartComment: '', // комментарий к контрагенту
          //заказы
          is_newOrderOnMessage: false, // новый заказ на входящее сообщение, bool
          orderStatusList: [], // список доступных статусов заказа, arr of hrefData
          newOrderStatus: null, // выбранный статус заказаа, hrefData
          organizaionsList: [], // список организаций, arr of hrefData
          currentOrganization: null, // выбранная организация
          orderComment: '', // комментарий к заказу
          excludeStatusList: [], // список статусов-исключений
        };
      },
    methods: {
      selectTab(tabName) {
        this.selected_tab = tabName;
      },
      handleMultiselectUpdate(){
        this.moysklad_edited_content = true;
      },
      addTemplate() {
        if (this.events[this.selected_event].dataInstance.length > 20) {
          return;
        }
        this.events[this.selected_event].dataInstance.push({
          active: false,
          textData: "",
          recipient: "",
          onFieldsData: [],
          stateListData: [],
          additionalParamData: [],
        });
        this.selected_template = this.events[this.selected_event].dataInstance.length - 1;
        this.moysklad_edited_content = true;
      },
      addAdditionalParam() {
        this.events[this.selected_event].dataInstance[this.selected_template].additionalParamData.push(JSON.parse(JSON.stringify(this.events[this.selected_event].additionalParams[0])));
      },
      removeAdditionalParam(index) {
        this.events[this.selected_event].dataInstance[this.selected_template].additionalParamData.splice(index, 1);
      },
      reset_button(selected_template) {
        this.$swal.fire({
          title: 'Удалить шаблон?',
          text: "Вы не сможете отменить это действие!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#FC5A5A',
          cancelButtonColor: '#23BB86',
          confirmButtonText: 'Да, удалить!',
          cancelButtonText: 'Отмена'
        }).then((result) => {
          if (result.isConfirmed) {
            this.events[this.selected_event].dataInstance.splice(selected_template, 1);
            this.selected_template = 0;
            this.moysklad_edited_content = true;
          }
        });  
      },
      toggleDocumentStatuses(is_watcher) {

        if (!is_watcher) {
          this.selected_template = 0;
        }
        console.log(this.events[this.selected_event].dataInstance[this.selected_template].onFieldsData);
        if (this.events[this.selected_event].dataInstance[this.selected_template].onFieldsData.filter(d => d.code == "{state}").length > 0) {
          this.showDocumentStatuses = true;
        } else {
          this.showDocumentStatuses = false;
        }
      },
      toggleStatus(profile_button) {  
        this.phone_error = "";
        if (this.$store.state.user.moysklad && !profile_button) {
          let enabledTemplates = this.events[this.selected_event].dataInstance.filter(e => e.active);
          if (enabledTemplates.length > 0) {
            this.events[this.selected_event].active = true;
            this.moysklad_edited_content = true;
          } else {
            this.events[this.selected_event].active = false;
            this.moysklad_edited_content = true;
          }
        }
      },
      insertTextAtCursor(text) {
        let input = document.querySelector('#reciever_input');
        // console.log(input);
        // Проверяем, есть ли поддержка selectionStart (современные браузеры поддерживают это)
        if (input.selectionStart || input.selectionStart === 0) {
            // Запоминаем позицию курсора
            let startPos = input.selectionStart;
            let endPos = input.selectionEnd;
  
            // Вставляем текст и обновляем свойство value элемента input
            input.value = input.value.substring(0, startPos) +
                            text +
                            input.value.substring(endPos, input.value.length);
  
            // Перемещаем курсор в конец вставленного текста
            input.selectionStart = input.selectionEnd = startPos + text.length;
        } else {
            // Просто добавляем текст в конец, если нет поддержки selectionStart
            input.value += text;
        }
  
        this.events[this.selected_event].dataInstance[this.selected_template].recipient = input.value;
  
        // Возвращаем фокус элементу input
        input.focus();
      },
      submit_button() {
        this.phone_error = "";
        if (!this.moysklad_edited_content) {
            return;
        }
        const responseData = {
            events: this.events,
            create_agent: this.is_creatingNewCounterpart,
            create_order: this.is_newOrderOnMessage,
            onMessageSettings: {
              createAgentSettings: {
                defaultStatus: this.defaultCounterpartStatus === null ? {value: '', href: ''} : this.defaultCounterpartStatus,
                allStatuses: this.counterpartStatusList,
                defaultComment: this.counterpartComment,
                defaultGroupTags: this.newCounterpartGroups,
              },
              createOrderSettings: {
                defaultStatus: this.newOrderStatus === null ? {value: '', href: ''} : this.newOrderStatus,
                defaultOrganization: this.currentOrganization === null ? {value: '', href: ''} : this.currentOrganization,
                allStatuses: this.orderStatusList,
                allOrganizations: this.organizaionsList,
                excludeStatusList: this.excludeStatusList,
                defaultComment: this.orderComment,
              },
            }
        };
        console.log(responseData);
        this.moysklad_sending = true;
        this.$axios.post(
            "https://" +
            window.location.host +
            "/mysklad/getform/submit?profile_id=" + this.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad,
           {
            events: this.events,
            create_agent: this.is_creatingNewCounterpart,
            create_order: this.is_newOrderOnMessage,
            onMessageSettings: {
              createAgentSettings: {
                defaultStatus: this.defaultCounterpartStatus === null ? {value: '', href: ''} : this.defaultCounterpartStatus,
                allStatuses: this.counterpartStatusList,
                defaultComment: this.counterpartComment,
                defaultGroupTags: this.newCounterpartGroups,
              },
              createOrderSettings: {
                defaultStatus: this.newOrderStatus === null ? {value: '', href: ''} : this.newOrderStatus,
                allStatuses: this.orderStatusList,
                allOrganizations: this.organizationsList,
                defaultOrganization: this.currentOrganization === null ? {value: '', href: ''} : this.currentOrganization,
                excludeStatusList: this.excludeStatusList,
                defaultComment: this.orderComment,
              },
            }
          },
        {
            headers: {
            Authorization: this.$store.state.token,
            Token: this.$store.state.user.moysklad_token,
            },
        }
        )
        .then(response => {
        this.moysklad_sending = false;
        this.moysklad_edited_content = false;
        this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Данные сохранены",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
            });
        })
        .catch((error) => {
        if ('errorField' in error.response.data) {
            console.log('Ключ errorField существует');
            if (error.response.data.errorField == 'phone') {
                this.phone_error = error.response.data.detail;
            }
        } else {
            console.log('Ключ errorField не существует');
        }
        this.moysklad_sending = false;
        this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "warning",
            title: "Ошибка сохранения данных",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
            });
        });
      },
      reload() {
        console.log("forceUpdate");
        this.$forceUpdate();
      },
      addTag (tag) {
        this.counterpartGroupTagsList.push(tag)
        this.newCounterpartGroups.push(tag)
        this.handleMultiselectUpdate();
      },
      handleEnter(event) {
      // Обрабатываем нажатие Enter
        if (event?.key === 'Enter') {
          this.$nextTick(() => {
            const input = this.$refs['counterpart_tags'];
            if (input) {
              input.focus();
            }
          });
        }
      },
      textAreaResize(val) {
        let element = this.$refs[`textarea${val}`];

        // Сбросить высоту перед расчетом scrollHeight
        element.style.height = 'auto';

        // Установить новую высоту, учитывая ограничения
        element.style.height = Math.min(element.scrollHeight, 520) + 'px';
      },
      createAgentClearHandler(){
        if (!this.is_creatingNewCounterpart) {
          this.is_newOrderOnMessage = false;
          this.createOrderClearHandler();
          this.defaultCounterpartStatus = null;
          this.newCounterpartGroups = [];
          this.counterpartComment = '';
        }
      },
      createOrderClearHandler(){
        if (!this.is_newOrderOnMessage) {
          this.newOrderStatus = null;
          this.currentOrganization = null;
          this.orderComment = '';
          this.excludeStatusList = [];
        }
        if(this.is_newOrderOnMessage) {
          this.currentOrganization = this.organizationsList[0] || null;
        }
      },
    },
    watch: {
        update: {
            handler: function(newValue) {
              this.reload();
            }
        },
        events: {
            handler: function(newValue) {
              if (this.initialLoad) {
                this.initialLoad = false;
                return;
              }
              this.toggleDocumentStatuses(true);
              console.log('я изменилось');
              this.moysklad_edited_content = true;
            },
            deep: true
        },
        defaultCounterpartStatus: {
          handler: function(newValue) {
            console.log(this.defaultCounterpartStatus)
          }
        },
        is_creatingNewCounterpart() {
          this.$nextTick(() => {
            $(this.$el).find('[data-toggle="tooltip"]').tooltip();
          });
        },
        is_newOrderOnMessage() {
          this.$nextTick(() => {
            $(this.$el).find('[data-toggle="tooltip"]').tooltip();
          });
        },
        selected_tab() {
          this.$nextTick(() => {
            $(this.$el).find('[data-toggle="tooltip"]').tooltip();
          });
        },

    },
    props: ["current_profile", "crm_type", "update"],
    mounted(){
        $(this.$el).find('[data-toggle="tooltip"]').tooltip();
        const hash = window.location.hash.slice(1);
        this.selected_tab = hash || 'templates';
        this.$loading.show({delay:0})
        this.$axios.get(
            "https://" +
            window.location.host +
            "/mysklad/getJsonData?profile_id=" + this.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad,
            {
              headers: {
                Authorization: this.$store.state.token,
                Token: this.$store.state.user.moysklad_token,
              },
            }
          )
          .then(response => {
            console.log(response.data);
            this.$loading.hide();
            this.events = response.data.events;
            this.toggleDocumentStatuses();
            //словари для контрагентов
            this.counterpartStatusList = [...response.data.onMessageSettings.createAgentSettings.allStatuses];
            this.counterpartGroupTagsList = response.data.onMessageSettings.createAgentSettings.defaultGroupTags || [];
            this.newCounterpartGroups = [...this.counterpartGroupTagsList]
            //текущие значения для контрагентов
            this.is_creatingNewCounterpart = response.data.create_agent;
            this.counterpartComment = response.data.onMessageSettings.createAgentSettings.defaultComment || '';
            this.defaultCounterpartStatus = response.data.onMessageSettings.createAgentSettings.defaultStatus.value === '' ? null : response.data.onMessageSettings.createAgentSettings.defaultStatus;
            //словари для заказов
            this.is_newOrderOnMessage = response.data.create_order;
            this.organizationsList = [...response.data.onMessageSettings.createOrderSettings.allOrganizations];
            this.orderStatusList = response.data.onMessageSettings.createOrderSettings.allStatuses;
            //текущие значения для заказов
            this.orderComment = response.data.onMessageSettings.createOrderSettings.defaultComment || '';
            this.newOrderStatus = response.data.onMessageSettings.createOrderSettings.defaultStatus.value === '' ? null : response.data.onMessageSettings.createOrderSettings.defaultStatus;
            this.currentOrganization = response.data.onMessageSettings.createOrderSettings.defaultOrganization.value === '' ? this.organizationsList[0] || null : response.data.onMessageSettings.createOrderSettings.defaultOrganization;
            this.excludeStatusList = response.data.onMessageSettings.createOrderSettings.excludeStatusList;

            Object.keys(this.$refs).forEach(refKey => {
            if (refKey.startsWith('textarea')) {
              this.textAreaResize(refKey.replace('textarea', ''));
            }
            });
          })
          .catch((error) => {
            this.$loading.hide();
            this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "warning",
                title: "Ошибка получения данных",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
            });
        
  
        const sidebar = document.querySelector(".sidebar");
        if(window.screen.width < 768){
          sidebar.classList.add("sidebar-mini");
        }
        
        let Scrollbar;
        if ($(".data-scrollbar").length) {
  
          let scr = document.querySelector('.data-scrollbar');
  
          Scrollbar = window.Scrollbar;
          let scrollbar = Scrollbar.init(scr, {
            continuousScrolling: true,
          });
  
          window.addEventListener("wheel", event => {
            if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
              localStorage['transform'] = scrollbar.scrollTop;
              // console.log(localStorage['transform']);
            }
          });
  
          if (localStorage['transform']) {
            // console.log(localStorage['transform']);
            scrollbar.scrollTop = localStorage['transform'];
          }
        }
      
    },
    beforeDestroy(){
      $(this.$el).find('[data-toggle="tooltip"]').tooltip('dispose');
    },
    components: {
        SwitchToggle,
        CrmTextarea,
        Multiselect,
    },
  };
  </script>
  <style>
  textarea {
    resize: none;
  }
  .form-check-label {
    color: #6C757D;
  }
  .notifications_settings > .responder_head_margin {
    margin-bottom: 30px;
  }
  .mysklad_nav > * {
    box-sizing: content-box;
  }
  .mysklad_nav {
    border-bottom: 2px solid #D3D3D3;
  }
  .mysklad_nav_item {
    text-align: center;
  }
  .mysklad_nav_item.templates {
    min-width: 260px;
  }
  .mysklad_nav_item.settings {
    min-width: 150px;
  }
  .mysklad_nav_link:active,
  .mysklad_nav_link:hover,
  .mysklad_nav_link {
    position: relative;
    text-decoration: none;
    color: #6C757D;
    text-transform: uppercase;
  }
  .mysklad_nav_item:not(.active):hover > .mysklad_nav_link {
    color: #23BB86;
  }
  .active > .mysklad_nav_link {
    color: #23BB86;
  }
  .active > .mysklad_nav_link::after {
    content: ''; /* Выводим пустое содержимое */
    position: absolute; /* Абсолютное позиционирование */
    background: #23BB86; /* Цвет линии */
    left: 0; /* Положение линии слева */
    bottom: -2px; /* Положение линии */
    width: 100%; /* Линия на ширину текста */
    height: 2px; /* Высота линии */
    z-index: 999;
   }
   .span[data-toggle="tooltip"] {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .selected_template_info {
    margin-bottom: 12px;
    margin-left: auto;
  }
  /*Мультиселект приводим к виду обычных селектов*/
  .multiselect {
    border: 1px solid #8A92A6;
    border-radius: 0.25rem;
    z-index:999;
  }
  .multiselect_select {
    right: 0;
    top: 0;
    width: 3rem;
  }
  
  .multiselect__select:before {
    position: relative;
    display: inline-block;
    right: 0.25rem;
    top: calc(50% - 10px);
    width: 12px;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    border-style: none;
    border-width: 0;
    border-color: transparent;
  }   
</style>