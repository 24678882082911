var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "crm_block",
      class: { active: _vm.profile.crm_type !== null },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "crm_block_title d-flex align-items-center justify-content-between",
        },
        [
          _vm.profile.crm_type === null &&
          !this.$store.state.user.moysklad &&
          !this.$store.state.amo_mode
            ? _c("div", [_vm._v("Интеграции")])
            : _vm._e(),
          _vm._v(" "),
          _vm.profile.crm_type === "bitrix24"
            ? _c("div", [_vm._v("Интеграция с Битрикс24")])
            : _vm._e(),
          _vm._v(" "),
          _vm.profile.crm_type === "amo" ||
          (this.$store.state.amo_mode && _vm.profile.crm_type === null)
            ? _c("div", [_vm._v("Интеграция с amoCRM")])
            : _vm._e(),
          _vm._v(" "),
          _vm.profile.crm_type === "moysklad"
            ? _c("div", [_vm._v("Интеграция с Мойсклад")])
            : _vm._e(),
          _vm._v(" "),
          _vm.profile.crm_type === "retailcrm"
            ? _c("div", [_vm._v("Интеграция с RetailCRM")])
            : _vm._e(),
          _vm._v(" "),
          _vm.profile.crm_type !== null
            ? _c("div", [
                _c("div", { staticClass: "crm_badge" }, [_vm._v("Подключена")]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.profile.crm_type === null
        ? _c(
            "form",
            {
              ref: "crmForm",
              staticClass: "crm_block_select",
              attrs: { action: "/crm/add" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            },
            [
              !this.$store.state.user.moysklad && !this.$store.state.amo_mode
                ? _c("div", [
                    _c("input", {
                      attrs: { type: "hidden", name: "profile_id" },
                      domProps: { value: _vm.profile.uuid },
                    }),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCrm,
                            expression: "selectedCrm",
                          },
                        ],
                        staticClass: "form-select",
                        attrs: { name: "crm", id: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedCrm = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        !this.$store.state.amo_mode ||
                        (this.$store.state.amo_mode &&
                          this.$store.state.current_profile.platform == "av")
                          ? _c(
                              "option",
                              {
                                attrs: { value: "", disabled: "" },
                                domProps: { selected: _vm.isMoyskladNull },
                              },
                              [_vm._v("Выберите интеграцию")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "bitrix24" } }, [
                          _vm._v("Битрикс24"),
                        ]),
                        _vm._v(" "),
                        this.$store.state.current_profile.platform !== "av"
                          ? _c("option", { attrs: { value: "amo" } }, [
                              _vm._v("amoCRM"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        this.$store.state.user.moysklad
                          ? _c(
                              "option",
                              { attrs: { value: "moysklad", selected: "" } },
                              [_vm._v("Мойсклад")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        this.$store.state.current_profile.platform !== "av" &&
                        !this.$store.state.user.moysklad
                          ? _c("option", { attrs: { value: "moysklad" } }, [
                              _vm._v("Мойсклад"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _vm.selectedCrm == "" && !this.$store.state.amo_mode
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2",
                        attrs: { type: "submit" },
                      },
                      [_c("span", [_vm._v("Подключить")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedCrm == "amo" && !this.$store.state.amo_mode
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "amocrm_oauth btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2",
                        attrs: {
                          charset: "utf-8",
                          "data-client-id": _vm.amoClientID,
                          "data-compact": "false",
                          "data-state":
                            this.$store.state.current_profile.uuid +
                            "," +
                            this.$store.state.token,
                          "data-mode": "popup",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.amoOAUTH.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Подключить")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.$store.state.amo_mode
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "crmConnect btn resp-button btn-billing btn-billing-crm text-decoration-none",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.crmConnect.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Подключить")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedCrm === "moysklad" &&
                this.$store.state.user.moysklad
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "moyskladConnect btn resp-button btn-billing btn-billing-crm text-decoration-none",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.moyskladConnect.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Подключить")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedCrm === "moysklad" &&
                !this.$store.state.user.moysklad
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "moyskladConnect btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.moyskladConnect2.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Подключить")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedCrm === "bitrix24"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "bitrixConnect btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.bitrixConnect.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Подключить")])]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.profile.crm_type !== null
        ? _c(
            "div",
            { staticClass: "crm_block_content" },
            [
              _vm.profile.crm_type !== "bitrix24" &&
              _vm.profile.crm_type !== "moysklad" &&
              _vm.profile.crm_type !== "retailcrm"
                ? _c(
                    "inertia-link",
                    {
                      staticClass: "crm_link_green",
                      attrs: {
                        href:
                          "/crm/" +
                          _vm.profile.crm_type +
                          "?profile_id=" +
                          _vm.profile.uuid,
                      },
                    },
                    [_vm._v("Настроить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "moysklad"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_green",
                      attrs: {
                        href:
                          "/crm/" +
                          _vm.profile.crm_type +
                          "?profile_id=" +
                          _vm.profile.uuid +
                          "#templates",
                      },
                    },
                    [_vm._v("Шаблоны")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "moysklad"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_green ms-2 ms-lg-3",
                      attrs: {
                        href:
                          "/crm/" +
                          _vm.profile.crm_type +
                          "?profile_id=" +
                          _vm.profile.uuid +
                          "#settings",
                      },
                    },
                    [_vm._v("Настройки")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "bitrix24"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_green",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.bitrixLink.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Настроить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "amo"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_red ms-2 ms-lg-3",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.crmDisconnect.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Отключить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "bitrix24"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_red ms-2 ms-lg-3",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.bitrixDisconnect.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Отключить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "moysklad"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_red ms-2 ms-lg-3",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.moyskladDisconnect.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Отключить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.crm_type === "retailcrm"
                ? _c(
                    "a",
                    {
                      staticClass: "crm_link_red ms-2 ms-lg-3",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.retailDisconnect.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Отключить")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }