var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("ul", { staticClass: "nav mysklad_nav responder_head_margin" }, [
      _c(
        "li",
        {
          staticClass: "nav-item mysklad_nav_item templates",
          class: { active: _vm.selected_tab === "templates" },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link mysklad_nav_link",
              attrs: { "aria-current": "page", href: "#templates" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectTab("templates")
                },
              },
            },
            [_vm._v("Шаблоны уведомлений")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item mysklad_nav_item settings",
          class: { active: _vm.selected_tab === "settings" },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link mysklad_nav_link",
              attrs: { href: "#settings" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectTab("settings")
                },
              },
            },
            [_vm._v("Настройки")]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.selected_tab === "templates"
      ? _c("div", { staticClass: "notification_templates" }, [
          _c("div", { staticClass: "responder_head_margin" }, [
            _c(
              "div",
              {
                staticClass:
                  "form-label d-flex justify-content-between align-items-center",
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "d-flex align-items-center",
                    attrs: {
                      "data-toggle": "tooltip",
                      "data-placement": "right",
                      title:
                        "Обязательный  параметр. На какое событие будет срабатывать отправка шаблона уведомления",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                            fill: "#6c757d",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected_event,
                      expression: "selected_event",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { name: "profile" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selected_event = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.toggleDocumentStatuses,
                    ],
                  },
                },
                _vm._l(_vm.events, function (t, i) {
                  return _c("option", { domProps: { value: i } }, [
                    t.active ? _c("span", [_vm._v("🟢")]) : _vm._e(),
                    !t.active ? _c("span", [_vm._v("⚪")]) : _vm._e(),
                    _vm._v(" " + _vm._s(t.name)),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _vm._m(1),
          ]),
          _vm._v(" "),
          _vm.events.length > 0
            ? _c("div", { staticClass: "responder_head_margin" }, [
                _c(
                  "div",
                  { staticClass: "d-flex template_buttons_ms" },
                  [
                    _vm._l(
                      this.events[this.selected_event].dataInstance,
                      function (template, i) {
                        return _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "selected_template_button",
                              class: { active: _vm.selected_template == i },
                              on: {
                                click: function ($event) {
                                  _vm.selected_template = i
                                },
                              },
                            },
                            [_vm._v("Шаблон " + _vm._s(i + 1))]
                          ),
                        ])
                      }
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "selected_template_add" }, [
                      this.events[this.selected_event].dataInstance.length < 20
                        ? _c(
                            "a",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                display: "block",
                              },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addTemplate()
                                },
                              },
                            },
                            [_vm._v("Добавить шаблон")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      this.events[this.selected_event].dataInstance.length > 19
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#D3D3D3",
                                "margin-top": "3px",
                                display: "block",
                              },
                            },
                            [_vm._v("Добавить шаблон")]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "selected_template_info d-flex align-items-center",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-flex align-items-center align-self-center",
                            attrs: {
                              "data-toggle": "tooltip",
                              "data-placement": "right",
                              title:
                                "На каждое событие может быть создано несколько шаблонов с различными настройками",
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "16",
                                  height: "16",
                                  viewBox: "0 0 16 16",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                    fill: "#6c757d",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.events.length > 0
            ? _c("div", { staticClass: "responder_head_margin" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-label d-flex justify-content-between align-items-center",
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "d-flex align-items-center",
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "right",
                          title: "Включить или выключить конкретный шаблон",
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                fill: "#6c757d",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("switch-toggle", {
                      key:
                        _vm.selected_uuid +
                        "." +
                        _vm.events[_vm.selected_event].type +
                        "." +
                        _vm.events[_vm.selected_event].value +
                        "." +
                        _vm.key_iter +
                        "." +
                        _vm.selected_template,
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        responder: null,
                        uid: "222",
                        model:
                          _vm.events[_vm.selected_event].dataInstance[
                            _vm.selected_template
                          ].active,
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(
                            _vm.events[_vm.selected_event].dataInstance[
                              _vm.selected_template
                            ],
                            "active",
                            $event
                          )
                        },
                        toggler: _vm.toggleStatus,
                      },
                    }),
                    _vm._v(" "),
                    _vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ].active
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bot_mini_text always_visible d-inline-block initial active",
                          },
                          [_vm._v("\n            ВКЛЮЧЕН")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ].active
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bot_mini_text always_visible d-inline-block initial",
                          },
                          [_vm._v("\n            ВЫКЛЮЧЕН")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.events.length > 0 &&
          _vm.events[_vm.selected_event].type == "UPDATE"
            ? _c("div", { staticClass: "responder_head_margin" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-label d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("b", [_vm._v("Параметры события")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "d-flex align-items-center",
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "right",
                          title:
                            "Обязательный параметр. При каком именно событии (изменении какого поля в документе) будет отправлен шаблон уведомления",
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                fill: "#6c757d",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "code",
                          placeholder: "Выберите параметр",
                          options:
                            _vm.events[_vm.selected_event].onFieldsParams,
                          multiple: true,
                          taggable: true,
                          "hide-selected": true,
                        },
                        model: {
                          value:
                            _vm.events[_vm.selected_event].dataInstance[
                              _vm.selected_template
                            ].onFieldsData,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.events[_vm.selected_event].dataInstance[
                                _vm.selected_template
                              ],
                              "onFieldsData",
                              $$v
                            )
                          },
                          expression:
                            "events[selected_event].dataInstance[selected_template].onFieldsData",
                        },
                      },
                      [
                        _c("span", {
                          staticStyle: { display: "none" },
                          attrs: { slot: "noResult" },
                          slot: "noResult",
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "noOptions" }, slot: "noOptions" },
                          [
                            _vm._v(
                              "\n            Нет доступных параметров\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(3),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.events.length > 0 &&
          _vm.events[_vm.selected_event].type == "UPDATE" &&
          _vm.showDocumentStatuses
            ? _c("div", { staticClass: "responder_head_margin" }, [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          label: "name",
                          "track-by": "code",
                          placeholder: "Выберите статус",
                          options:
                            _vm.events[_vm.selected_event].stateListParams,
                          multiple: true,
                          taggable: true,
                          "hide-selected": true,
                        },
                        model: {
                          value:
                            _vm.events[_vm.selected_event].dataInstance[
                              _vm.selected_template
                            ].stateListData,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.events[_vm.selected_event].dataInstance[
                                _vm.selected_template
                              ],
                              "stateListData",
                              $$v
                            )
                          },
                          expression:
                            "events[selected_event].dataInstance[selected_template].stateListData",
                        },
                      },
                      [
                        _c("span", {
                          staticStyle: { display: "none" },
                          attrs: { slot: "noResult" },
                          slot: "noResult",
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "noOptions" }, slot: "noOptions" },
                          [
                            _vm._v(
                              "\n            Нет доступных статусов\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(5),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.events.length > 0
            ? _c(
                "div",
                { staticClass: "responder_head_margin" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-label d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("b", [_vm._v("Дополнительные параметры события")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "right",
                            title:
                              "Необязательный параметр. Это дополнительный фильтр на отправку уведомления. Уведомление будет отправлено только в случае, если текст/значение указанного поля соответствует заданному здесь",
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                  fill: "#6c757d",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ].additionalParamData,
                    function (paramData, p) {
                      return _c(
                        "div",
                        { staticClass: "row mb-2 gx-2 gx-md-4" },
                        [
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.events[_vm.selected_event]
                                        .dataInstance[_vm.selected_template]
                                        .additionalParamData[p].code,
                                    expression:
                                      "events[selected_event].dataInstance[selected_template].additionalParamData[p].code",
                                  },
                                ],
                                key: "select_adp_" + p,
                                staticClass: "form-select",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.events[_vm.selected_event]
                                        .dataInstance[_vm.selected_template]
                                        .additionalParamData[p],
                                      "code",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.events[_vm.selected_event].additionalParams,
                                function (t, i) {
                                  return _c(
                                    "option",
                                    { domProps: { value: t.code } },
                                    [_vm._v(_vm._s(t.name))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.events[_vm.selected_event].dataInstance[
                                      _vm.selected_template
                                    ].additionalParamData[p].value,
                                  expression:
                                    "events[selected_event].dataInstance[selected_template].additionalParamData[p].value",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "name",
                                placeholder: "Значение поля равно",
                              },
                              domProps: {
                                value:
                                  _vm.events[_vm.selected_event].dataInstance[
                                    _vm.selected_template
                                  ].additionalParamData[p].value,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.events[_vm.selected_event].dataInstance[
                                      _vm.selected_template
                                    ].additionalParamData[p],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-1 d-flex align-items-center justify-contant-center",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    color: "#9d9d9d",
                                    border: "none",
                                    background: "none",
                                    "font-size": "28px",
                                    padding: "0",
                                  },
                                  attrs: {
                                    type: "button",
                                    "aria-label": "Удалить параметр",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeAdditionalParam(p)
                                    },
                                  },
                                },
                                [_vm._v("×")]
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "selected_template_add" }, [
                    _vm.events[_vm.selected_event].dataInstance.length <= 20
                      ? _c(
                          "a",
                          {
                            staticStyle: {
                              "margin-top": "12px",
                              display: "block",
                              color: "#6C757D",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addAdditionalParam()
                              },
                            },
                          },
                          [_vm._v("Добавить параметр")]
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.events.length > 0
            ? _c("div", { staticClass: "responder_head_margin" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-label d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("b", [_vm._v("Получатель")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "d-flex align-items-center",
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "right",
                          title:
                            "Обязательный параметр. По умолчанию рекомендуем ставить {agent.phone} - это значит, что телефон получателя будет браться из карточки контрагента. Также вы можете ввести несколько переменных/номеров через запятую",
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                fill: "#6c757d",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.events[_vm.selected_event].dataInstance[
                            _vm.selected_template
                          ].recipient,
                        expression:
                          "events[selected_event].dataInstance[selected_template].recipient",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.phone_error != "" },
                    attrs: {
                      type: "text",
                      name: "name",
                      placeholder:
                        "Можно ввести несколько получателей через запятую",
                      id: "reciever_input",
                    },
                    domProps: {
                      value:
                        _vm.events[_vm.selected_event].dataInstance[
                          _vm.selected_template
                        ].recipient,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.events[_vm.selected_event].dataInstance[
                            _vm.selected_template
                          ],
                          "recipient",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "input_desc" },
                      [
                        _vm.phone_error != ""
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(_vm.phone_error)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.events[_vm.selected_event].recipientParams.fields,
                          function (rval, rkey) {
                            return _c("div", [
                              _c("b", [_vm._v(_vm._s(rval) + ":")]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "crm_var",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.insertTextAtCursor(rkey)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(rkey))]
                              ),
                            ])
                          }
                        ),
                        _vm._v(" "),
                        _vm._m(6),
                      ],
                      2
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.events.length > 0
            ? _c(
                "div",
                { staticClass: "responder_head_margin" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-label d-flex justify-content-between align-items-center",
                    },
                    [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "right",
                            title:
                              "Обязательный параметр. Значения в переменные подтягиваются автоматически из документа. Список доступных переменных указан снизу, кликабельно",
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                  fill: "#6c757d",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("crm-textarea", {
                    key:
                      _vm.events[_vm.selected_event].type +
                      "." +
                      _vm.events[_vm.selected_event].value +
                      "." +
                      _vm.selected_template,
                    attrs: {
                      placeholder: "Введите текст шаблона",
                      uid:
                        _vm.events[_vm.selected_event].type +
                        "_" +
                        _vm.events[_vm.selected_event].value +
                        "_" +
                        _vm.selected_template,
                      text: _vm.events[_vm.selected_event].dataInstance[
                        _vm.selected_template
                      ].textData,
                      text_variables: _vm.events[_vm.selected_event].textParams,
                    },
                    on: {
                      "update:text": function ($event) {
                        return _vm.$set(
                          _vm.events[_vm.selected_event].dataInstance[
                            _vm.selected_template
                          ],
                          "textData",
                          $event
                        )
                      },
                      "update:text_variables": function ($event) {
                        return _vm.$set(
                          _vm.events[_vm.selected_event],
                          "textParams",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.selected_tab === "settings"
      ? _c("div", { staticClass: "notification_settings mb-2" }, [
          _vm._m(8),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("div", { staticClass: "responder_head_margin" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "me-2 d-flex align-items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.is_creatingNewCounterpart,
                          expression: "is_creatingNewCounterpart",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        id: "createNewCounterpart",
                        name: "createNewCounterpart",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.is_creatingNewCounterpart)
                          ? _vm._i(_vm.is_creatingNewCounterpart, null) > -1
                          : _vm.is_creatingNewCounterpart,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.is_creatingNewCounterpart,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.is_creatingNewCounterpart = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.is_creatingNewCounterpart = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.is_creatingNewCounterpart = $$c
                            }
                          },
                          () => {
                            _vm.createAgentClearHandler()
                            this.moysklad_edited_content = true
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "createNewCounterpart" },
                      },
                      [
                        _vm._v(
                          "Создавать нового контрагента по входящему сообщению"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "right",
                        title:
                          "Если написал новый клиент, которого нет в CRM, то будет создан новый контрагент",
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "16",
                            height: "16",
                            viewBox: "0 0 16 16",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                              fill: "#6c757d",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.is_creatingNewCounterpart
              ? _c("div", [
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newCounterpartStatus" },
                              },
                              [
                                _vm._v(
                                  "Какой статус устанавливать у контрагента"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "При создании нового контрагента будет устанавливаться выбранный статус",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.defaultCounterpartStatus,
                                  expression: "defaultCounterpartStatus",
                                },
                              ],
                              staticClass: "form-select",
                              attrs: {
                                name: "newCounterpartStatus",
                                id: "newCounterpartStatus",
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.defaultCounterpartStatus = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  () => {
                                    this.moysklad_edited_content = true
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { selected: "", disabled: "" } },
                                [_vm._v("Выберите статус")]
                              ),
                              _vm._v(" "),
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("Не выбран"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.counterpartStatusList,
                                function (item) {
                                  return _c(
                                    "option",
                                    { domProps: { value: item } },
                                    [_vm._v(_vm._s(item.value))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newCounterpartTags" },
                              },
                              [_vm._v("Какие группы добавлять контрагенту")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "При создании нового контрагента будут установлены указанные группы. Напишите название группы и нажмите Enter, можно задать несколько значений",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("Multiselect", {
                              ref: "counterpart_tags",
                              attrs: {
                                options: _vm.counterpartGroupTagsList,
                                multiple: true,
                                "close-on-select": false,
                                taggable: true,
                                showNoResults: false,
                                showNoOptions: false,
                                placeholder: "Выберите или введите новый тег",
                                "tag-placeholder": "Введите новый тег",
                              },
                              on: {
                                tag: _vm.addTag,
                                select: _vm.handleMultiselectUpdate,
                                remove: _vm.handleMultiselectUpdate,
                                open: _vm.handleEnter,
                              },
                              model: {
                                value: _vm.newCounterpartGroups,
                                callback: function ($$v) {
                                  _vm.newCounterpartGroups = $$v
                                },
                                expression: "newCounterpartGroups",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newCounterpartTags" },
                              },
                              [
                                _vm._v(
                                  "Какой комментарий добавлять контрагенту"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "При создании нового контрагента будет добавлен комментарий. Можно оставить пустым",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.counterpartComment,
                                expression: "counterpartComment",
                              },
                            ],
                            ref: "textarea1",
                            staticClass: "form-control",
                            attrs: {
                              name: "counterpart_comment",
                              id: "counterpart_comment",
                              placeholder: "Введите комментарий",
                            },
                            domProps: { value: _vm.counterpartComment },
                            on: {
                              change: () => {
                                this.moysklad_edited_content = true
                                _vm.textAreaResize(1)
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.counterpartComment = $event.target.value
                                },
                                function ($event) {
                                  return _vm.textAreaResize(1)
                                },
                              ],
                              cut: function ($event) {
                                return _vm.textAreaResize(1)
                              },
                              copy: function ($event) {
                                return _vm.textAreaResize(1)
                              },
                              paste: function ($event) {
                                return _vm.textAreaResize(1)
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "responder_head_margin" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-stretch justify-content-between",
                  class: { disabled: !_vm.is_creatingNewCounterpart },
                },
                [
                  _c("div", { staticClass: "me-2 d-flex align-items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.is_newOrderOnMessage,
                          expression: "is_newOrderOnMessage",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        name: "newOrderOnMessage",
                        id: "newOrderOnMessage",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.is_newOrderOnMessage)
                          ? _vm._i(_vm.is_newOrderOnMessage, null) > -1
                          : _vm.is_newOrderOnMessage,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.is_newOrderOnMessage,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.is_newOrderOnMessage = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.is_newOrderOnMessage = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.is_newOrderOnMessage = $$c
                            }
                          },
                          () => {
                            _vm.createOrderClearHandler()
                            this.moysklad_edited_content = true
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "newOrderOnMessage" },
                      },
                      [_vm._v("Создавать новый заказ по входящему сообщению")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "span",
                      {
                        staticClass: "d-flex align-items-center",
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "right",
                          title:
                            "При входящем сообщении будет автоматически создан новый заказ покупателя, если нет созданного ранее",
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                fill: "#6c757d",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.is_newOrderOnMessage
              ? _c("div", [
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newOrderStatus" },
                              },
                              [_vm._v("Какой статус устанавливать у заказа")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "При создании нового заказа будет устанавливаться выбранный статус",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newOrderStatus,
                                  expression: "newOrderStatus",
                                },
                              ],
                              staticClass: "form-select",
                              attrs: { name: "newOrderStatus" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.newOrderStatus = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  () => (this.moysklad_edited_content = true),
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: "",
                                  },
                                },
                                [_vm._v("Выберите статус")]
                              ),
                              _vm._v(" "),
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("Не выбран"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.orderStatusList, function (item) {
                                return _c(
                                  "option",
                                  { domProps: { value: item } },
                                  [_vm._v(_vm._s(item.value))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newOrderStatus" },
                              },
                              [_vm._v("Статусы заказов - исключения")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "Новый заказ не будет создан, если у этого контрагента уже есть заказ в одном из этих статусов. Опция нужна для того, чтобы во время переписки с клиентом не создавались новые заказы на каждое его сообщение",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                options: _vm.orderStatusList,
                                showNoResults: false,
                                showNoOptions: false,
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                placeholder: "Выберите статусы для исключения",
                                label: "value",
                                "track-by": "value",
                              },
                              on: {
                                open: _vm.handleMultiselectUpdate,
                                select: _vm.handleMultiselectUpdate,
                                remove: _vm.handleMultiselectUpdate,
                              },
                              model: {
                                value: _vm.excludeStatusList,
                                callback: function ($$v) {
                                  _vm.excludeStatusList = $$v
                                },
                                expression: "excludeStatusList",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newOrderStatus" },
                              },
                              [_vm._v("На какую организацию оформлять заказ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "При создании нового заказа будет устанавливаться выбранная организация",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentOrganization,
                                  expression: "currentOrganization",
                                },
                              ],
                              staticClass: "form-select",
                              attrs: { name: "currentOrganization" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.currentOrganization = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  () => (this.moysklad_edited_content = true),
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { selected: "", disabled: "" } },
                                [_vm._v("Выберите организацию")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.organizationsList, function (item) {
                                return _c(
                                  "option",
                                  { domProps: { value: item } },
                                  [_vm._v(_vm._s(item.value))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "responder_head_margin" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-stretch justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "newCounterpartTags" },
                              },
                              [_vm._v("Какой комментарий добавлять к заказу")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-flex align-items-center",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title:
                                    "При создании нового заказа будет добавлен комментарий. Можно оставить пустым",
                                  "aria-label":
                                    "Какой комментарий добавлять к заказу",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.orderComment,
                                expression: "orderComment",
                              },
                            ],
                            ref: "textarea2",
                            staticClass: "form-control",
                            attrs: {
                              name: "order_comment",
                              id: "order_comment",
                              placeholder: "Введите комментарий к заказу",
                            },
                            domProps: { value: _vm.orderComment },
                            on: {
                              change: () => {
                                this.moysklad_edited_content = true
                                _vm.textAreaResize(2)
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.orderComment = $event.target.value
                                },
                                function ($event) {
                                  return _vm.textAreaResize(2)
                                },
                              ],
                              cut: function ($event) {
                                return _vm.textAreaResize(2)
                              },
                              copy: function ($event) {
                                return _vm.textAreaResize(2)
                              },
                              paste: function ($event) {
                                return _vm.textAreaResize(2)
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "responder_head_margin responder_submit_buttons" },
      [
        !_vm.moysklad_sending
          ? _c(
              "a",
              {
                staticClass: "resp_button_green",
                class: { disable: !_vm.moysklad_edited_content },
                on: { click: _vm.submit_button },
              },
              [_vm._v("Сохранить")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.moysklad_sending
          ? _c("a", { staticClass: "resp_button_green disable" }, [_vm._m(9)])
          : _vm._e(),
        _vm._v(" "),
        _vm.events.length > 0 &&
        _vm.events[_vm.selected_event].dataInstance.length > 1
          ? _c(
              "a",
              {
                staticClass: "resp_button_red ms-2",
                on: {
                  click: function ($event) {
                    return _vm.reset_button(_vm.selected_template)
                  },
                },
              },
              [_vm._v("Удалить шаблон")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("b", [_vm._v("Событие")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v("Выберите событие для настройки его шаблона"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("b", [_vm._v("Статус шаблона")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Выберите параметры, на изменение которых будет отправлено уведомление"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Статусы документа")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Выберите параметры, на изменение которых будет отправлено уведомление"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("b", [_vm._v("номер телефона:")]),
      _vm._v(" 79005551213"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("b", [_vm._v("Текст сообщения")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label responder_head_margin d-flex align-items-center justify-content-center",
      },
      [_c("span", [_c("b", [_vm._v("Входящие сообщения от клиентов")])])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }