<template>
  <div class="crm_block" :class="{'active': profile.crm_type !== null}"> 
    
    <div class="crm_block_title d-flex align-items-center justify-content-between">
      <div v-if="profile.crm_type === null && !this.$store.state.user.moysklad && !this.$store.state.amo_mode">Интеграции</div>
      <div v-if="profile.crm_type === 'bitrix24'">Интеграция с Битрикс24</div>
      <div v-if="profile.crm_type === 'amo' || (this.$store.state.amo_mode && profile.crm_type === null)">Интеграция с amoCRM</div>
      <!--<div v-if="profile.crm_type === 'moysklad' || this.$store.state.user.moysklad">Интеграция с Мойсклад</div>-->
      <div v-if="profile.crm_type === 'moysklad'">Интеграция с Мойсклад</div>
      <div v-if="profile.crm_type === 'retailcrm'">Интеграция с RetailCRM</div>
      <div v-if="profile.crm_type !== null">
        <div class="crm_badge">Подключена</div>
      </div>
    </div>
    <form @submit.prevent="handleSubmit" v-if="profile.crm_type === null" class="crm_block_select" ref="crmForm" action="/crm/add">
      <div v-if="!this.$store.state.user.moysklad && !this.$store.state.amo_mode">
        <input type="hidden" name="profile_id" :value="profile.uuid">
        <select v-model="selectedCrm" name="crm" id="" class="form-select">
          <option 
            v-if="!this.$store.state.amo_mode || (this.$store.state.amo_mode && this.$store.state.current_profile.platform == 'av')" 
            value="" 
            disabled 
            :selected="isMoyskladNull">Выберите интеграцию</option>
          <option value="bitrix24">Битрикс24</option>
          <option v-if="this.$store.state.current_profile.platform !== 'av'" value="amo">amoCRM</option>
          <!-- <option value="retail">RetailCRM</option> -->
          <option v-if="this.$store.state.user.moysklad" value="moysklad" selected>Мойсклад</option>
          <option v-if="this.$store.state.current_profile.platform !== 'av' && !this.$store.state.user.moysklad" value="moysklad">Мойсклад</option>
        </select>
      </div>
      <div>
        <button v-if="selectedCrm == '' && !this.$store.state.amo_mode" type="submit" class="btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2"><span>Подключить</span></button>
        <button v-if="selectedCrm == 'amo' && !this.$store.state.amo_mode"
          @click.prevent="amoOAUTH"
          class="amocrm_oauth btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2"
          charset="utf-8"
          :data-client-id="amoClientID"
          data-compact="false"
          :data-state="this.$store.state.current_profile.uuid + ',' + this.$store.state.token"
          data-mode="popup"
        ><span>Подключить</span></button>
        <button v-if="this.$store.state.amo_mode" @click.prevent="crmConnect" type="submit" class="crmConnect btn resp-button btn-billing btn-billing-crm text-decoration-none"><span>Подключить</span></button>
        <button v-if="selectedCrm === 'moysklad' && this.$store.state.user.moysklad" @click.prevent="moyskladConnect" type="submit" class="moyskladConnect btn resp-button btn-billing btn-billing-crm text-decoration-none"><span>Подключить</span></button>
        <button v-if="selectedCrm === 'moysklad' && !this.$store.state.user.moysklad" @click.prevent="moyskladConnect2" type="submit" class="moyskladConnect btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2"><span>Подключить</span></button>
        <button v-if="selectedCrm === 'bitrix24'" @click.prevent="bitrixConnect" class="bitrixConnect btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2"><span>Подключить</span></button>
        <!-- <button v-if="selectedCrm === 'retail'" @click.prevent="rcConnect" class="rcConnect btn resp-button btn-billing btn-billing-crm text-decoration-none ms-2"><span>Подключить</span></button> -->
      </div>
    </form>
    <div v-if="profile.crm_type !== null" class="crm_block_content">

      <inertia-link v-if="profile.crm_type !== 'bitrix24' && profile.crm_type !== 'moysklad' && profile.crm_type !== 'retailcrm' " :href="'/crm/'+profile.crm_type+'?profile_id='+profile.uuid" class="crm_link_green">Настроить</inertia-link>
      <a v-if="profile.crm_type === 'moysklad'" :href="'/crm/'+profile.crm_type+'?profile_id='+profile.uuid+'#templates'" class="crm_link_green">Шаблоны</a>
      <a v-if="profile.crm_type === 'moysklad'" :href="'/crm/'+profile.crm_type+'?profile_id='+profile.uuid+'#settings'" class="crm_link_green ms-2 ms-lg-3">Настройки</a>
      <a v-if="profile.crm_type === 'bitrix24'" @click.prevent="bitrixLink" href="#" class="crm_link_green">Настроить</a>
      
      <a v-if="profile.crm_type === 'amo'" @click.prevent="crmDisconnect" href="#" class="crm_link_red ms-2 ms-lg-3">Отключить</a>
      <a v-if="profile.crm_type === 'bitrix24'" @click.prevent="bitrixDisconnect" href="#" class="crm_link_red ms-2 ms-lg-3">Отключить</a>
      <a v-if="profile.crm_type === 'moysklad'" @click.prevent="moyskladDisconnect" href="#" class="crm_link_red ms-2 ms-lg-3">Отключить</a>
      <a v-if="profile.crm_type === 'retailcrm'" @click.prevent="retailDisconnect" href="#" class="crm_link_red ms-2 ms-lg-3">Отключить</a>
    </div>
  </div>
</template> 

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedCrm: '',
      bitrix_url: '',
      bitrix_error: '',
      amoClientID: window.location.host === 'wappi.pro' ? 'b10aa636-e22e-4205-b66f-d2d855d022dd' : '881286f2-d0fe-4f1c-9532-5ec2d2928e40',
    };
  },
  props: ["profile"],
  computed: {
    isMoyskladNull() {
      return this.$store.state.user.moysklad == null;
    },
    ...mapState({
      amoMode: state => state.amo_mode
    })
  },
  methods: {
    moyskladConnect2() {
      window.open("https://www.moysklad.ru/integratsii/apps/wappi-wappi/", '_blank');
    },  
    bitrixConnect() {
      window.open("https://www.bitrix24.ru/apps/app/wappi.whatsapp_wappi/", '_blank');
    },
    bitrixLink() {
      if (this.bitrix_url.length > 0) {
        window.open(this.bitrix_url, '_blank');
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: this.bitrix_error,
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    handleSubmit() {
      
      if (this.selectedCrm == '') {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Выберите CRM систему",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      // this.$refs.crmForm.submit(); // Отправляем форму обычным способом для других значений
    },
    bitrixDisconnect() {
      if (confirm("Точно?")) {
        this.$axios.post(
          "https://" +
          window.location.host +
          "/bitrix/profile/delete?profile_id=" +
          this.$store.state.current_profile.uuid,
          {},
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(response => {
          this.$store.state.current_profile.crm_type = null;
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Битрикс24 отключен",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
        })
        .catch((error) => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: error.response.data.error,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    },
    retailDisconnect() {
      if (confirm("Точно?")) {
        this.$axios.post(
          "https://" + window.location.host + "/retailcrm/disconnect?profile_id=" + this.$store.state.current_profile.uuid,
          {},
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(response => {
          this.$store.state.current_profile.crm_type = null;
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "RetailCRM отключена",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
        })
        .catch((error) => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: error.response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    },
    async rcConnect() {
      const { isConfirmed: confirmed, value: result } = await this.$swal.fire({
        html: `
          <div style="text-align: left;line-height: 28px;">Введите <span class="c-content">адрес вашей crm</span> и <span class="c-content">Ключ доступа к API</span> из RetailCRM. <a class="billing-history text-decoration-underline" href="#">Где взять эти данные?</a></div>
          <div style="margin:16px 0;"><input autocomplete="off" class="form-control" style="margin-top: 24px;" type="text" id="inputCRMAddress" placeholder="Адрес crm (https://name.retailcrm.ru)"></div>
          <div><input autocomplete="off" class="form-control" style="margin-top: 24px;" type="text" id="inputCRMAPI" placeholder="Ключ API (A39Q5UaTsn0B8dktdp0PrIpJv0XFb4Xk)"></div>
          <div><input autocomplete="off" class="form-control" style="margin-top: 24px;" type="text" id="inputUID" placeholder="User ID (ice143)"></div>
        `,
        title: `<div style="text-align: left;">Введите данные вашей RetailCRM</div>`,
        showCloseButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: "#23BB86",
        preConfirm: () => {
          let inputCRMAddress = document.getElementById('inputCRMAddress').value;
          let inputCRMAPI = document.getElementById('inputCRMAPI').value;
          let inputUID = document.getElementById('inputUID').value;
          
          if (!inputCRMAddress) {
            this.$swal.showValidationMessage('Пожалуйста, введите Адрес crm');
            return;
          }

          if (!inputCRMAPI) {
            this.$swal.showValidationMessage('Пожалуйста, введите Ключ API');
            return;
          }

          if (!inputUID) {
            this.$swal.showValidationMessage('Пожалуйста, введите User ID');
            return;
          }

          return {
            crm_address: inputCRMAddress,
            api_key: inputCRMAPI,
            user_id: inputUID,
          };
        }
      });

      if (confirmed) {
        console.log(result);
        this.$axios.post(
          "https://" + window.location.host + "/retailcrm/connect?profile_id=" + this.$store.state.current_profile.uuid,
          {
            crm_address: result.crm_address,
            api_key: result.api_key,
            user_id: result.user_id,
          },
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(response => {
          this.$store.state.current_profile.crm_type = "retail";
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "RetailCRM подключена",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
          });
          this.$inertia.visit('/crm/retail?profile_id=' + this.$store.state.current_profile.uuid);
        })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "warning",
            title: error.response.data.detail,
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
          });
        });
      }
    },
    crmDisconnect() {
      if (confirm("Точно?")) {
        this.$axios.post(
          "https://" +
          window.location.host +
          "/amocrm/disconnect?profile_id=" +
          this.$store.state.current_profile.uuid,
          {},
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(response => {
          this.$store.state.current_profile.crm_type = null;
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "amoCRM отключена",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
        })
        .catch((error) => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: error.response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    },
    crmConnect() {
      this.$axios.post(
        "https://" +
          window.location.host +
        "/amocrm/connect?profile_id=" + this.$store.state.current_profile.uuid + "&amo_acid=" + this.$store.state.amo_id,
        {},
        {
          headers: {
            Authorization: this.$store.state.token,
          },
        }
      )
      .then(response => {
        this.$store.state.current_profile.crm_type = "amo";
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "amoCRM подключена",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
        this.$inertia.visit('/crm/amo?profile_id=' + this.$store.state.current_profile.uuid);
      })
      .catch((error) => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: error.response.data.detail,
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    },
    moyskladConnect() {
      this.$axios.post(
        "https://" +
          window.location.host +
        "/mysklad/getform/activateProfile?profile_id=" + this.$store.state.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad, 
        {},
        {
          headers: {
            Authorization: this.$store.state.token,
            Token: this.$store.state.user.moysklad_token,
          },
        }
      )
      .then(response => {
        this.$store.state.current_profile.crm_type = "moysklad";
        console.log("MOYSKLAD CONNECT");
        
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Мойсклад подключен",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
       
        this.$inertia.visit('/crm/moysklad?profile_id=' + this.$store.state.current_profile.uuid);
      })
      .catch((error) => {
        console.log("MOYSKLAD CONNECT ERROR");
        console.log(error);
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Ошибка подключения Мойсклад",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    },
    moyskladDisconnect() {
      this.$axios.post(
        "https://" +
          window.location.host +
        "/mysklad/getform/deactivateProfile?profile_id=" + this.$store.state.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad, 
        {},
        {
          headers: {
            Authorization: this.$store.state.token,
            Token: this.$store.state.user.moysklad_token,
          },
        }
      )
      .then(response => {
        this.$store.state.current_profile.crm_type = null;
        console.log("MOYSKLAD DISCONNECT");
        
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Мойсклад отключен",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
       
        // this.$inertia.visit('/crm/moysklad?profile_id=' + this.$store.state.current_profile.uuid);
      })
      .catch((error) => {
        console.log("MOYSKLAD CONNECT ERROR");
        console.log(error);
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Ошибка отключения Мойсклад",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    },
    receiveOAuthMessage(event) {
      var oauth_scripts = document.querySelectorAll('.amocrm_oauth');
    
      oauth_scripts.forEach(function (oauth_script) {
        if (event.data.client_id && oauth_script.dataset.clientId && event.data.client_id === oauth_script.dataset.clientId) {
          oauth_script.dataset.error = event.data.error;
          if (oauth_script.dataset.errorCallback) {
            try {
              var errorCallback = eval(oauth_script.dataset.errorCallback);
              if (typeof errorCallback === 'function') {
                errorCallback(event.data);
              }
            } catch (e) {
              //noop
            }
          }
        }
      });
    },
    receiveNewLocation(event) {
      if (event.data.url) {
        window.location = event.data.url;
      }
    },
    amoOAUTH () {
      var oauth_script = document.querySelector('.amocrm_oauth');
      var client_id = oauth_script.dataset.clientId,
        state = oauth_script.dataset.state,
        mode = oauth_script.dataset.mode || 'popup',
        name = oauth_script.dataset.name || null,
        description = oauth_script.dataset.description || null,
        logo = oauth_script.dataset.logo || null,
        redirect_uri = oauth_script.dataset.redirect_uri || null,
        secrets_uri = oauth_script.dataset.secrets_uri || null,
        scopes = oauth_script.dataset.scopes || null,
        origin = window.location.href || null,
        final_scopes;

      if ((!client_id || !oauth_script) && !(name && description && redirect_uri && secrets_uri && scopes)) {
        console.error('No client_id or client_secret or script tag or metadata');
        return;
      }

      var url_array = [
        'https://www.amocrm.ru/oauth/',
        '?state=', state,
        '&mode=', mode,
        '&origin=', origin,
      ];

      if (client_id) {
        url_array.push('&client_id=', client_id);
      } else if (name && description && redirect_uri && secrets_uri && scopes) {
        url_array.push('&name=', name);
        url_array.push('&description=', description);
        url_array.push('&redirect_uri=', redirect_uri);
        url_array.push('&secrets_uri=', secrets_uri);
        url_array.push('&logo=', logo);
        final_scopes = scopes.split(',');
        final_scopes.forEach(function(scope) {
          url_array.push('&scopes[]=', scope)
        });
      }

      var centerAuthWindow = function (url, title) {
        var w = 750;
        var h = 580;
        var dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : screen.left;
        var dual_screen_top = window.screenTop !== undefined ? window.screenTop : screen.top;

        var width = window.innerWidth
          ? window.innerWidth
          : (document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width);

        var height = window.innerHeight
          ? window.innerHeight
          : (document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height);

        var left = ((width / 2) - (w / 2)) + dual_screen_left;
        var top = ((height / 2) - (h / 2)) + dual_screen_top;

        var new_window = window.open(url, title, 'scrollbars, status, resizable, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        if (window.focus) {
          new_window.focus();
        }
      };

      centerAuthWindow(
        url_array.join(''), 
        'Предоставить доступ для интеграции'
      );
    }
  },
  watch: {
    amoMode(newVal, oldVal) {
      console.log('amoMode изменился с', oldVal, 'на', newVal);
      if(newVal) {
        console.log("AMO SELECTED");
        this.selectedCrm = 'amo';
      }
    },
    // selectedCrm(newVal, oldVal) {
    //   console.log('selectedCrm', newVal);
    // }
  },
  mounted() {
  console.log(this.profile.crm_type);
    window.addEventListener('message', this.receiveOAuthMessage, false);
    window.addEventListener('message', this.receiveNewLocation, false);
    
    if (this.$store.state.user.moysklad) {
      console.log("MOYSKLAD SELECTED");
      this.selectedCrm = 'moysklad';
    } 
    // if (this.$store.state.amo_mode && this.$store.state.current_profile.platform != 'av') {
    //   console.log("AMO SELECTED");
    //   this.selectedCrm = 'amo';
    // }

    if (this.profile.crm_type === 'bitrix24') {
      this.$axios.get(
        "https://" +
          window.location.host + "/bitrix/url/get?profile_id=" +
        this.profile.uuid,
        {
          headers: {
            Authorization: this.$store.state.token,
          },
        }
      )
      .then(response => {
        this.bitrix_url = response.data.link;
      })
      .catch((error) => {
        this.bitrix_error = error.response.data.error;
      });
    }
  },
}
</script>
